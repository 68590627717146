<template>
  <PageContainer
    :title="pageContainerConfig.title"
    :back="pageContainerConfig.back"
  >
    <template v-slot:content>
      <div class="page-segment" v-if="loading">
        <div class="page-segment-content">
          <Skeleton
            width="40%"
            height="26px"
            borderRadius="16px"
            class="margin-bottom"
          />
          <Skeleton
            width="100%"
            height="164px"
            borderRadius="16px"
            margin="16px 0"
            v-for="(v, i) in 4"
            :key="i"
          />
        </div>
      </div>

      <div class="page-segment" v-if="programs.length > 0 && !loading">
        <div class="page-segment-content">
          <ProgramCard
            class="margin-bottom-xs"
            v-for="(item, index) in programs"
            :key="index"
            :program="item"
            :country="user.country"
            :lang="$store.getters.language.lang"
            @click="goProgramDetail"
          />
        </div>
      </div>

      <!-- data zero -->
      <DataZeroSimple
        v-if="programs.length === 0 && !loading"
        :text="datazero.text"
        :image="datazero.image"
        :buttons="datazero.buttons"
      />
    </template>
  </PageContainer>
</template>

<script>
import {
  ProgramCard,
  PageContainer,
  NoEvents,
  Skeleton
} from '@seliaco/red-panda'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import { mapGetters } from 'vuex'
import { ProgramsEvent } from '@/types/events'

export default {
  components: {
    PageContainer,
    ProgramCard,
    DataZeroSimple,
    Skeleton
  },
  data () {
    const pageContainerConfig = {
      title: this.$translations.programs.title,
      back: {
        callback: () => this.$router.push({ name: 'Home' })
      }
    }
    const datazero = {
      text: this.$translations.programs.empty,
      image: NoEvents
    }

    return {
      pageContainerConfig,
      datazero
    }
  },
  created () {
    this.getPrograms()
    ProgramsEvent.Programs_Page_Viewed({
      page: 'Programs'
    })
  },
  methods: {
    getPrograms () {
      this.$store.dispatch('programs/getPrograms')
    },
    goProgramDetail (program) {
      ProgramsEvent.Programs_Button_Clicked({
        origin: this.$route.name,
        destination: 'Program detail',
        program: program.subtitle
      })
      this.$router.push({
        name: 'ProgramDetail',
        params: {
          id: program.id
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      programs: 'programs/getPrograms',
      loading: 'programs/getLoading'
    })
  }
}
</script>
